.tableTitle3{
    margin-top: -20px;
    position: absolute;
    margin-left: 6rem;
    font-size: 18px;
    font-weight: 400;
    color: #6f7884;
    width: 100px;
}

.ant-select-tree-list-holder::-webkit-scrollbar {
    width: 14px !important;
}

.ant-select-tree-list-holder::-webkit-scrollbar-track {
    background: var(--scrollbarBG)  !important;
}
.ant-select-tree-list-holder::-webkit-scrollbar-thumb {
    background-color: var(--thumbBG)  !important;
    border-radius: 6px !important;
    border: 3px solid var(--scrollbarBG) !important;
}