.login{
    background-color: #fff;
}
.loginContainer{
    max-width: 100%;
}
.loginLeft{
    width: 100%;
    height: 657px;
    background-image: url('../../assets/images/Group\ 244.png');
    background-size: cover;
    filter:invert(23%) sepia(77%) saturate(7094%) hue-rotate(191deg) brightness(50%) contrast(112%)
}
.loginRight{
    width: 100%;
    height: 650px;
    background-color: #fff;
    padding: 50px 90px;  
}
.noPadding{
    padding: 0;
}
.loginRight .btn{
    background-color: #233b5b !important;
}
.login .forgetP{
    text-align: center;
    color: #233b5b;
    font-weight: 500;
    font-size: 18px
}

