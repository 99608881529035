.ant-progress{
    top: -110px !important;
    left: -32px !important;
}
.ant-result-success .ant-result-title {
    color: rgb(2, 202, 2) !important;
    font-weight: 400 !important;
    font-size: 21px !important;
    line-height: 0.8 !important;
    text-align: center;
}
.ant-result-error .ant-result-title {
    color: rgb(255, 0, 0) !important;
    font-weight: 400 !important;
    font-size: 21px !important;
    line-height: 0.8 !important;
    text-align: center;
}
.ant-result-subtitle {
    margin-top: 10px;
    color: rgba(9, 9, 9, 0.772) !important;
    font-size: 14px;
    font-weight: 400 !important;
    line-height: 1.6;
    text-align: center;
}