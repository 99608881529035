.searchCountainer{
    height: 305px;
    overflow-y: auto;
    width: 100%;
    overflow-x: hidden;
}
.searchCountainer::-webkit-scrollbar {
    width: 14px;
}
.searchCountainer::-webkit-scrollbar-track {
    background: var(--scrollbarBG);
}
.searchCountainer::-webkit-scrollbar-thumb {
    background-color: var(--thumbBG) ;
    border-radius: 6px;
    border: 3px solid var(--scrollbarBG);
}
.search .ant-checkbox-wrapper {
    padding: 0px 20px;
}
.search .ant-checkbox {
    float: right;
    margin-top: 5px;
}
.search .ant-checkbox + span {
    padding-right: 8px;
    padding-left: 8px;
    font-weight: 600;
    font-size: 15px;
}
/*.search #theSearch{
    width: 100%;
    border: 1px solid #dedede;
    height: 40px;
    padding: 10px;
}*/
.categoryCard{
    /*border: 1px solid #aaaaaa;*/
    background-color: #ffffff;
    padding: 10px;
    margin-bottom: 1rem;
    cursor: pointer;
}
.categoryInfo{
    padding-top: 15px;
    text-align: right;
}
.categoryImg{
    text-align: end;
}
.categoryImg img{
    width: 80px;
    margin-top: 13px;
}
.categoryTop{
    width: 100%;
    height: 60px;
    margin-bottom: 2rem;
}
.noData{
    text-align: center;
    margin: 6rem auto;
    color: #233b5b;
    font-size: 18px;
    background-color: #FFFFFF !important
}
.formLabel{
    padding: 5px;
    font-weight: 600;
    color: #233b5b !important;
}
.uploadContainer{
    text-align: center;
    height: 180px;
    background: #f4fcfd;
    padding: 1rem;
}
.uploderImg{
    margin: auto;
    display: block;
    width: 80px;
    margin-bottom: 1rem;
}
.uploader{
    position: absolute;
    cursor: pointer;
    width: 95%;
    top: 0;
    z-index: 9;
    height: 100%;
    left: 15px;
    opacity: 0;
}
.removeImg{
    top: -18px;
    position: relative;
    left: -25%;
    color: red;
    font-size: 20px;
}
.hiddenButton{
    position: absolute;
    width: 92%;
    left: 4%;
    height: 76%;
    top: 0px;
    opacity: 0;
    z-index: 2;
}