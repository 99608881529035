.minusInput{
    background: red;
    color: #fff;
    text-align: center;
    width: 30px;
    height: 30px;
    padding: 6px 10px;
    border-radius: 20px;
    cursor: pointer;
    position: absolute;
    margin:0% 36%;
    top: 35px;
    font-size: 12px;
}
.addInputInner{
    background: green;
    color: #fff;
    text-align: center;
    width: 30px;
    height: 30px;
    padding: 4px 8px;
    border-radius: 20px;
    float: right;
    cursor: pointer;
    position: absolute;
    top: 35px;
}