
.MuiPaper-rounded-6{
    margin: 50px !important;
}
.controller-icon {
    cursor: pointer;
    margin: 5px !important;
    color: #26a69a !important;
    font-size: 18px;
}
.MuiTableCell-paddingCheckbox-131{
    display: none !important
}
.MUIDataTableHeadCell-data-147{
    font-size: 15px
}

.ant-skeleton-content > ul{
    display:none !important;
}
.ant-skeleton-content > h3{
    width:100% !important
}
.ant-skeleton-title{
    width:100% !important
}


.ant-skeleton-content > ul{
    display:none !important;
}
.ant-skeleton-content > h3{
    width:100% !important
}
