body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #eeeeee !important;
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
html {
  --scrollbarBG: #CFD8DC;
  --thumbBG: #90A4AE;
}
body::-webkit-scrollbar {
  width: 14px;
}

body::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}
body::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG) ;
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
}
/************************************form************************************/

.needs-validation .md-form label {
  left: 0rem;
  top: -24px;
}
.screenTable{
  background: #fff;
  border-radius: 5px;
  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
}

#components-layout-demo-responsive .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

.site-layout-sub-header-background {
  background: #fff;
}

.site-layout-background {
  background: #fff;
}
/************************************table filter************************************/

.showClass{
  height: 330px;
  transition: all 1s linear;
  margin: 20px 40px;
  overflow: hidden;
}
.showClass160{
  height: 160px;
  transition: all 1s linear;
  margin: 0px 40px;
  overflow: hidden;
}
.showClassC{
  height: 300px;
  transition: all 1s linear;
  margin: 0px 40px;
  overflow: hidden;
}
.hideClass{
  height: 0;
  overflow: hidden;
  transition: all 1s linear;
  margin: 20px 40px
}
.searchIcon{
  transition: all .4s linear;
  font-size: 24px;
  color: #00B483;
  padding: 4px;
  border-radius: 50%;
  /* color: #fff; */
  cursor: pointer;
  padding: 8px;
  border: 2px solid #fff;
  margin: 4px;
  margin-right: 1px;
}
.searchIcon:hover{
  transition: all .4s linear;
  border-radius: 50%;
  padding: 8px;
  color: #00B483;
  border: 2px solid #00B483;
}
/************************************info card************************************/

.infoCard{
  border: 1px solid transparent;
  background-color: #ffffff;
  border-radius: 6px;
  box-shadow: 0 1px 4px 0 rgba(0,0,0,.14);
  margin-top: 80px;
  margin-bottom: 40px;
}
.info h6{
  color: #999999 !important;
}
.infoCard > .btn.btn-block {
  margin: inherit;
  margin: 10px 15px !important;
  width: 92% !important;
  border-radius: 25px
}
.btn.btn-block {
  margin: inherit;
  margin-bottom: 20px;
}
/************************************edit card************************************/

.editCard{
  margin-top: 50px;
  margin-bottom: 0px;
  min-height: 400px;
  overflow: visible !important;
  outline:none
}
.editCard  .topIcon {
  color: #fff;
  padding-top: 28px;
  width: 80px;
  height: 80px;
  position: relative;
  margin-top: -6.6rem !important;
  margin-right: 4%;
  margin-left: 1.4rem;
  text-align: center;
  border-radius: 5px;
  background:#233b5b
}
.editCard > .tableTitle {
  margin-top: -40px;
  position: absolute;
  margin-left: 8rem;
  font-size: 18px;
  font-weight: 400;
  color: #6f7884;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
  text-align: center;
  width: 100%;
  font-size: 18px;
  font-weight: 400;
}
.ant-message{
  z-index: 9999;
}

.md-form>label{
  position: absolute;
  top: -30px !important;
  left: -2px !important;
}
/**********************************ant select************************************/
.ant-select-multiple.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background: #fff !important;
  color:#000
}
.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector{
  background: #fff !important;
  color:#000 !important
}
.ant-select-disabled.ant-select-multiple .ant-select-selection-item {
  color: #000;
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 35px !important;
}
.ant-select-show-search.ant-select-multiple .ant-select-selector{
  min-height: 35px !important;
}
.ant-select-single .ant-select-selector .ant-select-selection-item {
  font-weight: 400 !important;
}
/************************************upload************************************/
.fileUpload2{
  background-color: transparent;
  margin-top: 10px;
  height: 50px;
  box-shadow: none;
  border: 1px solid #dedede;
}
.upImgs2{
  height: 50px;
  opacity: 0;
  position: relative;
  width: 100%;
  z-index: 1000;
  cursor: pointer;
  float: left;
}
.uploadName{
  font-size: 13px;
  font-weight: 400;
  color: #000;
  font-family: auto;
}
.uploadParent{
  position: absolute;
  width: 100%;
  left: 0;
  text-align: center;
  margin-top: 9px;
}
.removePdf{
  margin-left: 10px;
  color: red;
  cursor: pointer;
}
.removeImg2{
  top: 30px;
  position: relative;
  right: 5%;
  float: right;
  color: red;
  font-size: 16px;
  cursor: pointer;
}

.removeImg3{
  top: 145px !important;
  position: absolute;
  right: 6%;
  color: red;
  font-size: 16px;
  cursor: pointer;
}
.imgName{
  font-size: 14px;
  font-weight: 400;
  color: black;
}
.videoName{
  font-size: 14px;
  font-weight: 400;
  color: black;
  margin-top: 28px;
  position: absolute
}
.preview{
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  float: left;
}
.preview img{
  width: 70px;
  height: 70px;
  margin: 10px;
}
.previewPdf{
  background-color: #eeeeee;
  height: 30px;
  padding:4px 6px 4px 15px;
  margin: 5px;
  border-radius: 18px;
  font-size: 14px;
  box-shadow: 0px 0px 1px 0px rgb(0 0 0 / 20%), 1px 1px 2px 0px rgb(0 0 0 / 14%), 0px -1px 3px 0px rgb(0 0 0 / 12%);
  color: darkslateblue;
}
.previewImg2{
  width: 65px !important;
  height: 65px !important;
  margin: 7px !important;
}
.previewVideo2{
  width: 65px !important;
  height: 65px !important;
  margin: 7px !important;
}
/************************************custom check box************************************/

.checking{
  height: 35px;
  background: #dedede;
  width: 100%;
  border-radius: 25px;
}
.checkingRight{
  width: 50%;
  height: 35px;
  float: right;
  border-top-right-radius: 21px;
  border-bottom-right-radius: 20px;
  font-weight: 500;
  cursor: pointer;
  text-align: center;
  transition: all .5s ease-in-out
}
.checkingLeft{
  height: 35px;
  float: left;
  border-top-left-radius: 21px;
  border-bottom-left-radius: 20px;
  width: 50%;
  cursor: pointer;
  text-align: center;
  transition: all .5s ease-in-out
}

.ant-picker-range {
  width: 100% !important;
}
.ant-picker-input>input[disabled] {
  color: #000 !important;
  background-color: #fff !important;
  border: none !important;
}
.ant-picker.ant-picker-disabled {
  background: #ffff !important;
}
.tagsValues{
  font-size: 15px;
  border-radius: 4px;
  background: #00B483;
  color: #fff;
  display: inline-block;
  padding: 2px 10px;
  margin: 2px 2px 4px;
  font-weight: 400;
  margin-bottom: 8px;
}
.labelBox{
  width:100%;
  margin:25px 14px;
}
.labelBox span{
  padding:10px;
  background:#00B483;
  border-bottom-left-radius: 14px;
  color:#fff;
  margin-top:25px
}
.disabled, :disabled {
  pointer-events: all !important;
}
.form-control:disabled, .form-control[readonly] {
  cursor: auto !important;
}
